import React, { FC } from "react";
import { Link, useHistory } from 'react-router-dom';

export const GoogleInstructionPage: FC = () => {
	const history = useHistory();

	return (
		<div className={'container'}>
			<p></p>
			<p><Link onClick={history.goBack} to={'/'}>&larr; back</Link></p>
      <h2>Брендирование мобильного приложения - подготовка google аккаунта клиента для публикации приложения</h2>
			<ol>
				<li>Зарегистрируйте учетную запись разработчика Google Play (если ранее не регистрировались)
					<a target='_blank'
						rel="noopener noreferrer"
						href="https://support.google.com/googleplay/android-developer/answer/6112435?hl=en#zippy=%2Cstep-complete-your-account-details">
						Дополнительная информация
					</a></li>
				<li>
					На странице <a target='_blank' rel="noopener noreferrer" href="https://play.google.com/console">Google Console</a> &rarr; All Apps, создайте новое приложение, следуя  <a target='_blank' rel="noopener noreferrer" href="https://support.google.com/googleplay/android-developer/answer/9859152?hl=ru">инструкции</a>
					<img className={'figure-img img-fluid'} src="/images/google-instructions/Screenshot 2021-04-09 at 00.03.46.png" alt=""/>
				</li>

				<li>
					Убедитесь что приняли соглашение <a href="https://play.google.com/about/play-app-signing-terms.html">Play App Signing Terms of Service</a> <br/>
					Для этого перейдите к вкладке Release &rarr; Setup &rarr; App Integrity
					<br/>
					<img className={'figure-img img-fluid'} src="/images/google-instructions/Screenshot 2021-04-09 at 00.00.41.png" alt=""/>
				</li>

				<li>Перейдите к вкладке Users and permissions и пригласите разработчика Эрливидео - dmitriy.grachev@erlyvideo.org, роль - Admin <a target='_blank' rel="noopener noreferrer" href="https://play.google.com/apps/publish/#AdminPlace">
					Дополнительная информация
				</a>
					<br/>
					<img className={'figure-img img-fluid'} src="/images/google-instructions/invite_users1.png" alt=""/>
					<br/>
					<img className={'figure-img img-fluid'} src="/images/google-instructions/invite_users2.png" alt=""/>
					<br/>
					<img className={'figure-img img-fluid'} src="/images/google-instructions/invite_users3_permissions.png" alt=""/>
					<br/>
					<img className={'figure-img img-fluid'} src="/images/google-instructions/invite_users4_invite.png" alt=""/>
				</li>

				<li>
					<p>Предоставьте менеджеру компании Эрливидео google playstore upload key.json файл для автоматического обновления бета версии андроид приложения</p>
					<p>Для этого создайте сервисный аккаунт:</p>
					<ul>
						<li>Откройте Google Play Console.</li>
						<li>Нажмите Settings → Developer Account → API access.</li>
						<li>Нажмите кнопку Create new service account.</li>
						<li>Перейдите по ссылке Google Developers Console в диалоговом окне, которое откроет новую вкладку / окно:

							<ul>
								<li>Нажмите кнопку CREATE SERVICE ACCOUNT в верхней части консоли разработчика Google.</li>
								<li>Укажите имя учетной записи службы и нажмите «Создать».</li>
								<li>Щелкните Выбрать роль, затем найдите и выберите Service Account User и продолжайте.</li>
								<li>Нажмите кнопку "Готово".</li>
								<li>Нажмите на Actions вертикальный трехточечный значок только что созданной учетной записи службы.</li>
								<li>Выберите в меню Manage keys.</li>
								<li>Нажмите ADD KEY -&gt; Create New Key.</li>
								<li>Убедитесь, что JSON выбран в качестве типа ключа, и нажмите CREATE.</li>
								<li>При появлении запроса сохраните файл на своем компьютере и запомните, где он был сохранен.</li>
							</ul></li>
						<li>Вернувшись в Google Play Console, нажмите ГОТОВО, чтобы закрыть диалоговое окно.</li>
						<li>Нажмите «Grant Access» для вновь добавленной учетной записи службы в нижней части экрана.</li>
						<li>Выберите разрешения, которые вы хотите иметь для этой учетной записи. Мы рекомендуем Release manager, но вы можете вручную установить все флажки и оставить некоторые из разрешений Release, обязательные разрешения - Release to Beta, опционально - Release to production.</li>
						<li>Нажмите Invite user, чтобы закончить.</li>
					</ul>
				</li>

			</ol>
    </div>
	)
}