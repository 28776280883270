import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseLoadSettings } from 'api';
import SettingStore from "stores/settings";
import { Base64 } from "models";
import { Epic, ofType, StateObservable } from "redux-observable";
import { switchMap, ignoreElements, map, filter } from "rxjs/operators";
import notificationStore, { NotificationStatus } from "stores/notification";
import { of } from "rxjs";
import defaultPng from "./default.json";
import { GState } from "index";
import fileToBase64 from "utils/fileToBase64";
import { isTransparent } from "utils/checkTransparent";
import { base64ConvertToImage } from "utils/base64ToImage";
import { FieldIsRequired } from "utils/messages";
export type InitialState = {
  banner: Base64;
  icon: Base64;
  logo: Base64;
  googlePlayBanner: Base64;
  foreground: Base64 | string;
  background: Base64 | string;
  errors_foreground: string | null;
  errors_background: string | null;
  errors_googlePlayBanner: string | null;
  errors_logo: string | null;
  errors_banner: string | null;
  errors_icon: string | null;
  errors_storeIcon: string | null;
  errors_storeBanner: string | null;
};
const _defaultPng: {
  banner: Base64;
  icon: Base64;
  logo: Base64;
  AndroidBanner: Base64;
  googlePlayBanner: Base64;
  iosBanner: Base64;
  background: Base64 | string;
} = {
  // @ts-ignore
  AndroidBanner: defaultPng.androidBanner,
  // @ts-ignore
  banner: defaultPng.banner,
  // @ts-ignore
  googlePlayBanner: defaultPng.googlePlayBanner,
  // @ts-ignore
  icon: defaultPng.icon,
  // @ts-ignore
  iosBanner: defaultPng.iosBanner,
  // @ts-ignore
  logo: defaultPng.logo,
  // @ts-ignore
  background: defaultPng.background,
};
export const initialState: InitialState = {
  banner: "",
  icon: "",
  logo: "",
  googlePlayBanner: "",

  background: "",
  foreground: "",
  errors_background: null,
  errors_foreground: null,
  errors_googlePlayBanner: null,
  errors_logo: null,
  errors_banner: null,
  errors_icon: null,
  errors_storeIcon: null,
  errors_storeBanner: null,
};

const fileStore = createSlice({
  name: "files",
  initialState,
  extraReducers: {
    [SettingStore.actions.initTokenIdSuccess.type](
      state,
      { payload }: PayloadAction<ResponseLoadSettings>
    ) {
      const { settings } = payload;
      state.banner = settings?.files?.banner || _defaultPng.banner;
      state.icon = settings?.files?.icon || _defaultPng.icon;
      state.logo = settings?.files?.logo || _defaultPng.logo;
      state.googlePlayBanner =
        settings?.files?.googlePlayBanner || _defaultPng.googlePlayBanner;
      state.background = settings?.files?.background || _defaultPng.background;
      state.foreground = settings?.files?.foreground || "";
    },
    [SettingStore.actions.agreeResetSettings.type](state) {
      state.banner = _defaultPng.banner;
      state.icon = _defaultPng.icon;
      state.logo = _defaultPng.logo;
      state.googlePlayBanner = _defaultPng.googlePlayBanner;
      state.foreground = _defaultPng.icon || "";
      state.background = "";
    },
    [SettingStore.actions.notAllIconAdded.type](state) {
      if (!state.banner) state.errors_banner = FieldIsRequired;
      if (!state.googlePlayBanner) state.errors_googlePlayBanner = FieldIsRequired;
      if (!state.icon) state.errors_icon = FieldIsRequired;
      if (!state.logo) state.errors_logo = FieldIsRequired;
      if (!state.background) state.errors_background = FieldIsRequired;
      if (!state.foreground) state.errors_foreground = FieldIsRequired;
    },
  },
  reducers: {
    changeBanner(state, { payload }: PayloadAction<Base64>) {
      state.banner = payload;
      state.errors_banner = null;
    },
    changeIcon(state, { payload }: PayloadAction<Base64>) {
      state.icon = payload;
      state.errors_icon = null;
    },
    changeErrorIcon(state, { payload }: PayloadAction<string>) {
      state.errors_icon = payload;
    },
    changeLogo(state, { payload }: PayloadAction<Base64>) {
      state.logo = payload;
      state.errors_logo = null;
    },
    changeFrontGroundImage(state, { payload }) { },
    changeFrontGroundError(state, { payload }: PayloadAction<string>) {
      state.errors_foreground = payload;
    },
    changeFrontGround(state, { payload }: PayloadAction<string | Base64>) {
      state.errors_foreground = null;
      state.foreground = payload;
    },
    changeBackGroundImage(state, { payload }) { },
    changeBackGroundError(state, { payload }: PayloadAction<string>) {
      state.errors_background = payload;
    },
    changeBackGround(state, { payload }: PayloadAction<Base64>) {
      state.errors_background = null;
      state.background = payload;
    },
    changeErrorLogo(state, { payload }: PayloadAction<string>) {
      state.errors_logo = payload;
    },
    changeErrorBanner(state, { payload }: PayloadAction<string>) {
      state.errors_banner = payload;
    },
    changeGooglePlayBanner(state, { payload }: PayloadAction<Base64>) {
      state.googlePlayBanner = payload;
      state.errors_googlePlayBanner = null;
    },
    changeErrorGooglePlayBanner(state, { payload }: PayloadAction<string>) {
      state.errors_googlePlayBanner = payload;
    },
  },
});

const base64InActionConvertToImage = (action: any) =>
  base64ConvertToImage(action.payload);
const validateOk = () => of().pipe(ignoreElements());
const filterChangeAction = (name: string) => name.indexOf("change") > -1;
const getTypeFromActionByName = (name: string) =>
  (fileStore.actions as { [key in string]: { type: string } })[name].type;
export const checkChange: Epic = (action$, state$: StateObservable<GState>) =>
  action$.pipe(
    ofType(
      ...Object.keys(fileStore.actions)
        .filter(filterChangeAction)
        .map(getTypeFromActionByName)
    ),
    switchMap(() =>
      state$.value.setting.isChange
        ? of().pipe(ignoreElements())
        : of(SettingStore.actions.settingsIsChange(true))
    )
  );

export const validateIcon: Epic = (action$) =>
  action$.pipe(
    ofType(fileStore.actions.changeIcon.type),
    switchMap(base64InActionConvertToImage),
    switchMap((image) => {
      if (image.width !== 1024 || image.height !== 1024)
        return of(
          fileStore.actions.changeErrorIcon(
            "Icon image resolution should have width 1024px and height 1024px"
          )
        );
      if (isTransparent(image))
        return of(
          fileStore.actions.changeErrorIcon(
            "Icon image should have no alpha channel and transparency"
          )
        );
      return validateOk();
    })
  );
export const validateImageLogo: Epic = (action$) =>
  action$.pipe(
    ofType(fileStore.actions.changeLogo.type),
    switchMap(base64InActionConvertToImage),
    switchMap((image) => {
      if (image.width !== 1200 || image.height > 462 || image.height < 258)
        return of(
          fileStore.actions.changeErrorLogo(
            "Logo image resolution should have width 1200px and height between 258px and 462px"
          )
        );
      return validateOk();
    })
  );

export const vlidateImageMenu: Epic = (action$) =>
  action$.pipe(
    ofType(fileStore.actions.changeBanner.type),
    switchMap(base64InActionConvertToImage),
    switchMap((image) => {
      if (image.width !== 1216 || image.height > 688 || image.height < 538)
        return of(
          fileStore.actions.changeErrorBanner(
            "Menu image resolution should have width 1216px and height between 538px and 688px"
          )
        );
      return validateOk();
    })
  );

export const validateGooglePlayBanner: Epic = (action$) =>
  action$.pipe(
    ofType(fileStore.actions.changeGooglePlayBanner.type),
    switchMap(base64InActionConvertToImage),
    switchMap((image) => {
      if (image.width !== 1024 || image.height !== 500)
        return of(
          fileStore.actions.changeErrorGooglePlayBanner(
            "Android store icon resolution should have width 1024px and height 500px"
          )
        );
      if (isTransparent(image))
        return of(
          fileStore.actions.changeErrorGooglePlayBanner(
            "Android store icon should have no alpha channel and transparency"
          )
        );
      return validateOk();
    })
  );

export const NotificationOnError: Epic = (action$) =>
  action$.pipe(
    filter(({ type }: { type: string, payload: string }) =>
      [...Object.keys(fileStore.actions), ...Object.keys(SettingStore.actions)]
        .filter((name) => {
          return name.indexOf("Error") > -1 && name !== 'setFieldErrors';
        })
        .map((name) => (fileStore.actions as any)[name]?.type || (SettingStore.actions as any)[name]?.type)
        .includes(type)
    ),
    map((action) =>
      notificationStore.actions.add([action.payload, NotificationStatus.danger])
    )
  );

  export const updateImageFrontGround: Epic = (action$) =>
  action$.pipe(
    ofType(fileStore.actions.changeFrontGroundImage.type),
    switchMap(({ payload }) => fileToBase64(payload)),
    switchMap(async (base64) => {
      const image = await base64ConvertToImage(base64);
      if (image.width !== 1024 || image.height !== 1024)
        return fileStore.actions.changeFrontGroundError(
          "Frontground Image App Icon resolution should have width 1024px and height 1024px"
        );
      if (!isTransparent(image)) {
        return fileStore.actions.changeFrontGroundError(
          "Frontground Image App Icon should have transparency"
        );
      }
      return fileStore.actions.changeFrontGround(base64);
    })
  );
export const updateImageBackground: Epic = (action$) =>
  action$.pipe(
    ofType(fileStore.actions.changeBackGroundImage.type),
    switchMap(({ payload }) => fileToBase64(payload)),
    switchMap(async (base64) => {
      const image = await base64ConvertToImage(base64);
      if (isTransparent(image))
        return fileStore.actions.changeBackGroundError(
          "Background Image App Icon should have no alpha channel and transparency"
        );
      if (image.width !== 1024 || image.height !== 1024)
        return fileStore.actions.changeBackGroundError(
          "Background Image App Icon resolution should have width 1024px and height 1024px"
        );
      return fileStore.actions.changeBackGround(base64);
    })
  );

export const epics = [
  validateImageLogo,
  vlidateImageMenu,
  NotificationOnError,
  validateGooglePlayBanner,
  validateIcon,
  checkChange,
  updateImageFrontGround,
  updateImageBackground,
];
export default fileStore;
