import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ESettingsStatus, loadSetting, ResponseLoadSettings, saveSetting } from 'api';
import restrictions from 'app/restrictions';
import Color from 'color';
import { GState, store } from 'index';
import { Base64, HexColor } from 'models';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, ignoreElements, map, mapTo, switchMap, tap, } from 'rxjs/operators';
import notificationStore, { NotificationStatus } from 'stores/notification';
import { FieldIsRequired } from 'utils/messages';

const defaultState = {
  primary: "#ededed",
  accent: "#2278c3",
  text: "#000000",
  error: "#f2183d",
  success: "#21d921",
};

interface ISettingsErrors {
  [key: string]: string;
}
interface ISettingsTouched {
  [key: string]: boolean;
}

export type InitialState = {
  tokenId: string | null;
  loading: boolean;

  color_accent: HexColor;
  color_onBtnColor: HexColor;
  color_btnColor: HexColor;

  color_onSurfaceBgIcon: HexColor;
  color_surfaceColor: HexColor;
  color_onSurfaceColor: HexColor;
  color_onSurfaceTitleColor: HexColor;
  color_onSurfaceBgColor: HexColor;

  color_errorText: HexColor;

  color_primary: HexColor;
  color_primary_light: HexColor;
  color_primaryShadow: HexColor;
  color_primaryTextHighLited: HexColor;

  color_successText: HexColor;

  color_text: HexColor;
  color_onMenuTextColor: HexColor;
  color_onMenuIconColor: HexColor;
  color_onMenuActiveBg: HexColor;
  color_onMenuActiveColor: HexColor;

  color_navbarColor: HexColor;
  color_navbarShadowColor: HexColor;
  color_navbarColorLight: HexColor;
  color_onNavbarTextInputColor: HexColor;
  color_onNavbarTitleColor: HexColor;
  color_onNavbarIconColor: HexColor;

  color_title: HexColor;
  color_text_hightlighted: HexColor;
  color_backgroundCommon: HexColor;

  short_description: string;
  description: string;
  title: string;
  promotion: string;
  contactInfo: string;
  copyright: string;
  privacyPolicy: string;
  support: string;
  tags: string;

  stores_apple_key_id: string;
  stores_apple_issuer_id: string;
  stores_apple_api_key: Base64;

  stores_google_api_key: Base64;

  errors_promotion: string | null;
  errors_title: string | null;
  errors_description: string | null;
  errors_contactInfo: string | null;
  errors_copyright: string | null;
  errors_privacyPolicy: string | null;
  errors_support: string | null;

  error: boolean;
  isModalReset: boolean;
  isChange: boolean;
  status: ESettingsStatus;

  fieldErrors: ISettingsErrors;
  fieldTouched: ISettingsTouched;
};

export const initialState: InitialState = {
  tokenId: null,
  isModalReset: false,
  error: false,
  loading: false,
  // Colors
  color_accent: "",
  color_onBtnColor: "",
  color_errorText: "",

  color_primary: "",
  color_primary_light: "",
  color_primaryShadow: "",
  color_primaryTextHighLited: "",

  color_navbarColor: "",
  color_navbarShadowColor: "",
  color_navbarColorLight: "",
  color_onNavbarTextInputColor: "",
  color_onNavbarTitleColor: "",
  color_onNavbarIconColor: "",

  color_successText: "",

  color_text: "",
  color_title: "",
  color_text_hightlighted: "",

  color_onMenuTextColor: "",
  color_onMenuIconColor: "",
  color_onMenuActiveBg: "",
  color_onMenuActiveColor: "",

  color_btnColor: "",
  color_surfaceColor: "",
  color_onSurfaceBgIcon: "",
  color_onSurfaceColor: "",
  color_onSurfaceTitleColor: "",
  color_onSurfaceBgColor: "",
  color_backgroundCommon: "",

  short_description: "",
  description: "",
  title: "",
  promotion: "",
  contactInfo: "",
  copyright: "",
  privacyPolicy: "",
  support: "",
  tags: "",
  status: ESettingsStatus.IN_PROGRESS,
  isChange: false,

  stores_apple_key_id: "",
  stores_apple_issuer_id: "",
  stores_apple_api_key: "",

  stores_google_api_key: "",

  errors_title: null,
  errors_promotion: null,
  errors_description: null,
  errors_contactInfo: null,
  errors_copyright: null,
  errors_privacyPolicy: null,
  errors_support: null,

  fieldErrors: {},
  fieldTouched: {},
};

const getSlight = (primary: HexColor) =>
  (Color(primary).isLight() ? -0.5 : 1) * 0.2;
const getMoar = (primary: HexColor) =>
  (Color(primary).isLight() ? -0.5 : 1) * 0.4;
const addTransparency = (clr: HexColor) =>
  Color(clr).isLight() ? `${clr}aa` : `${clr}77`;

const SettingStore = createSlice({
  name: "setting",
  initialState,
  reducers: {
    initTokenId(state, { payload }: PayloadAction<string>) {
      state.tokenId = payload;
      state.loading = true;
      state.error = false;
    },
    initTokenIdSuccess(state, { payload }: PayloadAction<ResponseLoadSettings>) {
      const { settings, status } = payload;

      state.status = status ?? ESettingsStatus.IN_PROGRESS;

      const primary = settings?.colors?.primary || defaultState.primary;
      const accent = settings?.colors?.accent || defaultState.accent;
      const text = settings?.colors?.text || defaultState.text;
      const error = settings?.colors?.errorText || defaultState.error;
      const success = settings?.colors?.successText || defaultState.success;
      state.description = settings.description || "";
      state.short_description = settings.short_description || "";
      state.title = settings.title || "";
      state.promotion = settings.promotion || "";
      state.contactInfo = settings.contactInfo || "";
      state.copyright = settings.copyright || "";
      state.privacyPolicy = settings.privacyPolicy || "";
      state.support = settings.support || "";

      state.stores_apple_key_id = settings.stores?.apple.key_id ?? "";
      state.stores_apple_issuer_id = settings.stores?.apple.issuer_id ?? "";
      state.stores_apple_api_key = settings.stores?.apple.api_key ?? "";

      state.stores_google_api_key = settings.stores?.google.api_key ?? "";

      state.tags = settings.tags ?? "";
      const moar = getMoar(primary);

      state.color_accent = accent;
      state.color_btnColor = accent;
      state.color_onBtnColor = Color(accent).isLight()
        ? Color(text).lighten(moar).hex()
        : "#ffffffcc";
      state.color_onSurfaceBgIcon = accent;

      state.color_errorText = error;
      state.color_onNavbarIconColor = addTransparency(text);

      state.color_primary = primary;
      state.color_successText = success;
      state.color_text = text;
      state.color_onMenuTextColor = text;
      state.color_onMenuIconColor = addTransparency(text);
      state.color_primaryTextHighLited = Color(text).lighten(moar).hex();

      const slight = getSlight(primary);
      state.color_primary_light = Color(primary).lighten(slight).hex();
      state.color_primaryShadow = Color(primary).darken(slight).hex();
      state.color_onMenuActiveBg = Color(primary).lighten(slight).hex();
      state.color_onMenuActiveColor = Color(text).lighten(slight).hex();
      state.color_surfaceColor = Color(primary).lighten(slight).hex();
      state.color_onSurfaceColor = addTransparency(text);
      state.color_onSurfaceTitleColor = Color(text).lighten(moar).hex();
      state.color_onSurfaceBgIcon = addTransparency(text);
      state.color_navbarColor = primary;
      state.color_navbarShadowColor = Color(primary).darken(slight).hex();
      state.color_navbarColorLight = Color(primary).lighten(slight).hex();
      state.color_onNavbarTextInputColor = text;
      state.color_onNavbarTitleColor = Color(text).lighten(moar).hex();
      state.color_onNavbarIconColor = addTransparency(text);
      state.color_backgroundCommon = Color(primary).darken(0.2).hex();
      state.loading = false;
    },
    initTokenIdError(state, { payload }: PayloadAction<string>) {
      state.error = true;
    },
    changeColorAccent(state, { payload }: PayloadAction<HexColor>) {
      const moar = getMoar(state.color_primary);
      state.color_accent = payload;
      state.color_btnColor = payload;
      state.color_onSurfaceBgIcon = payload;
      state.color_onBtnColor = Color(payload).isLight()
        ? Color(state.color_text).lighten(moar).hex()
        : "#ffffffcc";
    },
    changeColorErrorText(state, { payload }: PayloadAction<HexColor>) {
      state.color_errorText = payload;
    },
    changeColorPrimary(state, { payload }: PayloadAction<HexColor>) {
      state.color_primary = payload;
      const slight = getSlight(payload);
      state.color_primary_light = Color(payload).lighten(slight).hex();
      state.color_primaryShadow = Color(payload).darken(slight).hex();
      state.color_surfaceColor = Color(state.color_primary)
        .lighten(slight)
        .hex();
      state.color_onMenuActiveBg = Color(state.color_primary)
        .lighten(slight)
        .hex();
      state.color_navbarColor = state.color_primary;
      state.color_navbarShadowColor = Color(state.color_primary)
        .darken(slight)
        .hex();
      state.color_navbarColorLight = Color(state.color_primary)
        .lighten(slight)
        .hex();
      state.color_backgroundCommon = Color(state.color_primary)
        .darken(0.2)
        .hex();

      // text colors
      const nextTextColor = Color(payload).isLight() ? '#000000' : '#ffffff';
      state.color_text = nextTextColor;
      state.color_onMenuTextColor = nextTextColor;
      state.color_onMenuIconColor = addTransparency(nextTextColor);
      const moar = getMoar(state.color_primary);
      const slight2 = getSlight(nextTextColor);
      state.color_primaryTextHighLited = Color(nextTextColor).lighten(moar).hex();
      state.color_text_hightlighted = Color(nextTextColor).lighten(moar).hex();
      state.color_title = Color(nextTextColor).lighten(moar).hex();
      state.color_onBtnColor = Color(state.color_accent).isLight()
        ? Color(state.color_text).lighten(moar).hex()
        : "#ffffffcc";
      state.color_onMenuActiveColor = Color(state.color_text)
        .lighten(slight2)
        .hex();
      state.color_onSurfaceTitleColor = Color(state.color_text)
        .lighten(moar)
        .hex();
      state.color_onSurfaceBgIcon = addTransparency(state.color_text);
      state.color_onSurfaceColor = addTransparency(state.color_text);
      state.color_onNavbarTextInputColor = state.color_text;
      state.color_onNavbarTitleColor = Color(state.color_text)
        .lighten(moar)
        .hex();
      state.color_onNavbarIconColor = addTransparency(state.color_text);
    },
    changeColorSuccessText(state, { payload }: PayloadAction<HexColor>) {
      state.color_successText = payload;
    },
    changeColorText(state, { payload }: PayloadAction<HexColor>) {
      state.color_text = payload;

      state.color_onMenuTextColor = payload;
      state.color_onMenuIconColor = addTransparency(payload);
      const moar = getMoar(state.color_primary);
      const slight = getSlight(payload);
      state.color_primaryTextHighLited = Color(payload).lighten(moar).hex();
      state.color_text_hightlighted = Color(payload).lighten(moar).hex();
      state.color_title = Color(payload).lighten(moar).hex();
      state.color_onBtnColor = Color(state.color_accent).isLight()
        ? Color(state.color_text).lighten(moar).hex()
        : "#ffffffcc";
      state.color_onMenuActiveColor = Color(state.color_text)
        .lighten(slight)
        .hex();
      state.color_onSurfaceTitleColor = Color(state.color_text)
        .lighten(moar)
        .hex();
      state.color_onSurfaceBgIcon = addTransparency(state.color_text);
      state.color_onSurfaceColor = addTransparency(state.color_text);
      state.color_onNavbarTextInputColor = state.color_text;
      state.color_onNavbarTitleColor = Color(state.color_text)
        .lighten(moar)
        .hex();
      state.color_onNavbarIconColor = addTransparency(state.color_text);
    },
    changeContactInfo(state, { payload }: PayloadAction<string>) {
      state.contactInfo = payload;
      state.errors_contactInfo = null;
    },
    changeCopyright(state, { payload }: PayloadAction<string>) {
      state.copyright = payload;
      state.errors_copyright = null;
    },
    changePrivacyPolicy(state, { payload }: PayloadAction<string>) {
      state.privacyPolicy = payload;
      state.errors_privacyPolicy = null;
    },
    changeSupport(state, { payload }: PayloadAction<string>) {
      state.support = payload;
      state.errors_support = null;
    },
    changeDescription(state, { payload }: PayloadAction<string>) {
      state.description = payload;
    },
    changeShortDescription(state, { payload }: PayloadAction<string>) {
      state.short_description = payload;
    },
    changePromotion(state, { payload }: PayloadAction<string>) {
      state.promotion = payload;
      state.errors_promotion = null;
    },
    changeTags(state, { payload }: PayloadAction<string>) {
      state.tags = payload;
    },
    changeTitle(state, { payload }: PayloadAction<string>) {
      state.title = payload;
    },
    changeAppleKeyId(state, { payload }: PayloadAction<string>) {
      state.stores_apple_key_id = payload;
    },
    changeAppleIssuerId(state, { payload }: PayloadAction<string>) {
      state.stores_apple_issuer_id = payload;
    },
    changeAppleApiKey(state, { payload }: PayloadAction<Base64>) {
      state.stores_apple_api_key = payload;
      state.fieldTouched.stores_apple_api_key = true;
    },
    changeGoogleApiKey(state, { payload }: PayloadAction<Base64>) {
      state.stores_google_api_key = payload;
      state.fieldTouched.stores_google_api_key = true;
    },
    changeErrorTitle(state, { payload }: PayloadAction<string>) {
      state.errors_title = payload;
    },
    saveSetting(state) { },
    saveSettingsForReview(state) {
      Object.keys(restrictions).forEach(fieldName => {
        state.fieldTouched[fieldName] = true;
      });
    },
    resetSetting(state) {
      state.isModalReset = true;
    },
    cancelResetSettings(state) {
      state.isModalReset = false;
    },
    agreeResetSettings(state) {
      const primary = defaultState.primary;
      const accent = defaultState.accent;
      const text = defaultState.text;
      const error = defaultState.error;
      const success = defaultState.success;

      const moar = getMoar(primary);
      state.isModalReset = false;
      state.color_accent = accent;
      state.color_btnColor = accent;
      state.color_onBtnColor = Color(accent).isLight()
        ? Color(text).lighten(moar).hex()
        : "#ffffffcc";
      state.color_onSurfaceBgIcon = accent;

      state.color_errorText = error;
      state.color_onNavbarIconColor = addTransparency(text);

      state.description = "";
      state.title = "";
      state.color_primary = primary;
      state.tags = "";
      state.short_description = "";
      state.color_successText = success;
      state.color_text = text;
      state.color_onMenuTextColor = text;
      state.color_onMenuIconColor = addTransparency(text);
      state.color_primaryTextHighLited = Color(text).lighten(moar).hex();
      const slight = getSlight(primary);
      state.color_primary_light = Color(primary).lighten(slight).hex();
      state.color_primaryShadow = Color(primary).darken(slight).hex();
      state.color_onMenuActiveBg = Color(primary).lighten(slight).hex();
      state.color_onMenuActiveColor = Color(text).lighten(slight).hex();
      state.color_surfaceColor = Color(primary).lighten(slight).hex();
      state.color_onSurfaceColor = addTransparency(text);
      state.color_onSurfaceTitleColor = Color(text).lighten(moar).hex();
      state.color_onSurfaceBgIcon = addTransparency(text);
      state.color_navbarColor = primary;
      state.color_navbarShadowColor = Color(primary).darken(slight).hex();
      state.color_navbarColorLight = Color(primary).lighten(slight).hex();
      state.color_onNavbarTextInputColor = text;
      state.color_onNavbarTitleColor = Color(text).lighten(moar).hex();
      state.color_onNavbarIconColor = addTransparency(text);
      state.color_backgroundCommon = Color(primary).darken(0.2).hex();

      state.stores_apple_key_id = "";
      state.stores_apple_issuer_id = "";
      state.stores_apple_api_key = "";

      state.stores_google_api_key = "";

      state.status = ESettingsStatus.IN_PROGRESS;
    },
    settingsIsChange(state, { payload }: PayloadAction<boolean>) {
      state.isChange = payload;
    },
    notAllIconAdded(state) {

    },
    removeGoogleApiKey(state) {
      state.stores_google_api_key = "";
    },
    removeAppleApiKey(state) {
      state.stores_apple_api_key = "";
    },
    setFieldErrors(state, { payload }: PayloadAction<ISettingsErrors>) {
      state.fieldErrors = payload;
    },
    setFieldTouched(state, { payload }: PayloadAction<string>) {
      state.fieldTouched[payload] = true;
    },
    setStatus(state, { payload }: PayloadAction<ESettingsStatus>) {
      state.status = payload;
    },
  },
});

const filterChangeAction = (name: string) => name.indexOf("change") > -1 || name.indexOf("remove") > -1;
const filter = (name: string) => name.indexOf("changeColor") > -1;
const isColorProperty = (name: string) => name.indexOf("color_") > -1;
const getTypeFromActionByName = (name: string) =>
  (SettingStore.actions as { [key in string]: { type: string } })[name].type;
export const checkChange: Epic = (action$, state$: StateObservable<GState>) =>
  action$.pipe(
    ofType(
      ...Object.keys(SettingStore.actions)
        .filter(filterChangeAction)
        .map(getTypeFromActionByName)
    ),
    switchMap(() =>
      state$.value.setting.isChange
        ? of().pipe(ignoreElements())
        : of(SettingStore.actions.settingsIsChange(true))
    )
  );

export const updateAttributes: Epic = (
  action$,
  state$: StateObservable<GState>
) =>
  action$.pipe(
    ofType(
      ...Object.keys(SettingStore.actions)
        .filter(filter)
        .map(getTypeFromActionByName),
      SettingStore.actions.initTokenIdSuccess.type
    ),
    map(() => {
      Object.keys(state$.value.setting)
        .filter(isColorProperty)
        .forEach((property_name) => {
          document.body.style.setProperty(
            `--${property_name}`,
            (state$.value.setting as any)[property_name] as string
          );
        });
    }),
    ignoreElements()
  );

export const loadingEpic: Epic = (action$) =>
  action$.pipe(
    ofType(SettingStore.actions.initTokenId.type),
    switchMap(() =>
      loadSetting().pipe(
        map(({ data }) =>
          SettingStore.actions.initTokenIdSuccess({
            settings: data.settings,
            status: data.status
          })
        ),
        catchError((err) => of(SettingStore.actions.initTokenIdError("")))
      )
    )
  );

export const notAllIconAdded: Epic = (action$) =>
  action$.pipe(
    ofType(SettingStore.actions.notAllIconAdded.type),
    mapTo(
      notificationStore.actions.add([
        "All icons is required",
        NotificationStatus.danger,
      ])
    )
  );
export const saveEpic: Epic = (action$, state$: StateObservable<GState>) =>
  action$.pipe(
    ofType(
      SettingStore.actions.saveSetting.type,
      SettingStore.actions.saveSettingsForReview.type
    ),
    switchMap((action) => {
      const isSaveForReview = action.type === SettingStore.actions.saveSettingsForReview.type;
      // валидация файлов нужна при любом сохранении, чтобы не сохранять не правильные файлы даже в черновик
      if (
        Object.keys(state$.value.files).filter(
          (name) =>
            name.indexOf("errors") > -1 && (state$.value.files as any)[name]
        ).length !== 0
      )
        return of(
          notificationStore.actions.add([
            "One or more errors in image files",
            NotificationStatus.danger,
          ])
        );
      const { banner, logo, icon, googlePlayBanner, foreground, background } = state$.value.files;
      const files = [
        banner,
        logo,
        icon,
        googlePlayBanner,
        foreground,
        background,
      ];
      const settings = state$.value.setting;

      // при отправке на review, все поля должны быть заполнены
      if (isSaveForReview) {
        // проверка на наличие файлов
        if (files.filter(Boolean).length < files.length) {
          return of(SettingStore.actions.notAllIconAdded());
        }
        // scroll to first error
        const firstErrorFieldName = Object.keys(settings.fieldErrors)[0];

        // если есть ошибки полей, данные не отправляем
        if (firstErrorFieldName) {
          return of(
            notificationStore.actions.add([
              "Some fields have errors",
              NotificationStatus.danger,
            ])
          );
        }
      }
      const requestSettings: ResponseLoadSettings = !isSaveForReview ? {
        settings: {
          colors: {
            accent: state$.value.setting.color_accent,
            errorText: state$.value.setting.color_errorText,
            primary: state$.value.setting.color_primary,
            successText: state$.value.setting.color_successText,
            text: state$.value.setting.color_text,
          },
          short_description: state$.value.setting.short_description,
          description: state$.value.setting.description,
          title: state$.value.setting.title,
          contactInfo: state$.value.setting.contactInfo,
          copyright: state$.value.setting.copyright,
          privacyPolicy: state$.value.setting.privacyPolicy,
          promotion: state$.value.setting.promotion,
          support: state$.value.setting.support,
          tags: state$.value.setting.tags,
          files: {
            banner: state$.value.files.banner,
            icon: state$.value.files.icon,
            logo: state$.value.files.logo,
            googlePlayBanner: state$.value.files.googlePlayBanner,
            foreground: state$.value.files.foreground,
            background: state$.value.files.background,
          },
          stores: {
            apple: {
              key_id: state$.value.setting.stores_apple_key_id,
              issuer_id: state$.value.setting.stores_apple_issuer_id,
              api_key: state$.value.setting.stores_apple_api_key,
            },
            google: {
              api_key: state$.value.setting.stores_google_api_key,
            }
          }
        },
        status: state$.value.setting.status
      } : {
        settings: {} as ResponseLoadSettings['settings'],
        status: ESettingsStatus.REVIEW
      }
      return saveSetting(requestSettings).pipe(
        tap((t) => {
          store.dispatch(SettingStore.actions.settingsIsChange(false));
          if (isSaveForReview) {
            store.dispatch(SettingStore.actions.setStatus(ESettingsStatus.REVIEW));
          }
        }),
        mapTo(
          notificationStore.actions.add([
            "Settings saved successfully",
            NotificationStatus.success,
          ])
        ),
        catchError((err, caught) => {
            if (err?.response?.data?.errors) {
              return of(
                notificationStore.actions.add([
                  err?.response?.data?.errors,
                  NotificationStatus.danger,
                ])
              )
            }
            return of(
              notificationStore.actions.add([
                "Something went wrong",
                NotificationStatus.danger,
              ])
            )
          }
        )
      );
    }),
    catchError(err => {
      console.log(err);
      return of(notificationStore.actions.add([
        "Something went wrong",
        NotificationStatus.danger,
      ]))
    })
  );

export const validate: Epic = (
  action$,
  state$: StateObservable<GState>
) => action$.pipe(
  ofType(
    ...Object.keys(SettingStore.actions)
      .filter(filterChangeAction)
      .map(getTypeFromActionByName),
    SettingStore.actions.initTokenIdSuccess.type,
  ),
  switchMap(() => {
    const errors: ISettingsErrors = {};
    const settings = state$.value.setting;
    if (settings.description && settings.description === settings.short_description) {
      errors.short_description = 'Should not be equal to the description';
    }
    // validate required & regexp based on `restrictions`
    for (const restrictionsName in restrictions) {
      const settingsValue = settings[restrictionsName as keyof InitialState];
      if (typeof settingsValue !== 'undefined') {
        if(restrictions[restrictionsName].notEmpty && !settingsValue) {
          errors[restrictionsName] = FieldIsRequired;
        } else if(restrictions[restrictionsName].regExp && !restrictions[restrictionsName].regExp.test(settingsValue)) {
          errors[restrictionsName] = 'Wrong value';
        }
      }
    }

    return of(SettingStore.actions.setFieldErrors(errors));
  })
)

export const epics = [
  loadingEpic,
  saveEpic,
  updateAttributes,
  checkChange,
  notAllIconAdded,
  validate,
];
export default SettingStore;
