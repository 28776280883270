import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import React, { FC } from "react";
import { Link, useHistory } from 'react-router-dom';

export const AppleInstructionPage: FC = () => {
	const history = useHistory();

	return (
		<div className={'container'}>
			<p></p>
			<p><Link onClick={history.goBack} to={'/'}>&larr; back</Link></p>
      <h2>Брендирование мобильного приложения - подготовка apple аккаунта клиента для публикации приложения</h2>
			<p>Публикация приложения производится в учетной записи AppstoreConnect клиента.</p>
			<p>Создайте apple аккаунт (если отсутствует), следуя <ExternalLink to={'https://appleid.apple.com/account'}>инструкции apple</ExternalLink>
			</p>
			<p>
				Зарегистрируйте созданный аккаунт в Apple Developer Program как организацию следуя <ExternalLink to={'https://developer.apple.com/programs/enroll/'}>инструкции apple</ExternalLink>
			</p>
			<p>Убедитесь, что на странице <ExternalLink to={'https://developer.apple.com/account/#/membership'}></ExternalLink> поле <code>Entity Type</code> имеет значение <code>Company / Organization</code>. При необходимости нужно принять лицензионное соглашение apple</p>
			<p>
				<img className={'figure-img img-fluid'} src="/images/apple-instructions/membership.png" alt=""/>
			</p>
			<p>Убедитесь, что на странице <ExternalLink to={'https://appstoreconnect.apple.com/agreements/#/'}>https://appstoreconnect.apple.com/agreements/#/</ExternalLink> приняты соглашения для приложений с типом <code>Free Apps</code>
			</p>
			<p>
				<img className={'figure-img img-fluid'} src="/images/apple-instructions/agreements.png" alt=""/>
			</p>
			<p>Для выгрузки сборок брендированного приложения в testflight необходимо предоставить App Store Connect API Key</p>




			<ul>
				<li>создать API Key для App Store Connect на Странице <ExternalLink to={'https://developer.apple.com/documentation/appstoreconnectapi/creating_api_keys_for_app_store_connect_api'}>creating_api_keys_for_app_store_connect_api</ExternalLink>, выбрав role - Developer и скачать</li>
				<li>предоставить скачанный <code>API Key</code> файл, а также <code>KEY ID</code> и <code>Issuer ID</code> (эти данные указаны на странице со сгенерированным API KEY)</li>
			</ul>
			<p><img className={'figure-img img-fluid'} src="/images/apple-instructions/api-key.png" alt=""/></p>
			<p>Добавьте разработчика Эрливидео - dmitriy.grachev@erlyvideo.org - в список пользователей App Store Connect, укажите role - Admin <ExternalLink to={'https://developer.apple.com/support/roles/'}>Дополнительная информация</ExternalLink>.
			</p>
			<p>Права Admin'a нужны для заполнения на вкладке App Privacy информации о Data Collection.</p>
			<ul>
				<li>Эти данные могут быть заполнены держателем аккаунта (Account Holder) или администратором самостоятельно, следуя <ExternalLink to={'https://storage.erlyvideo.ru/s/jNQPZBsqZ3442Yr'}>образцам</ExternalLink>
				</li>
			</ul>
			<p>После успешной публикации приложения права разработчика можно будет ограничить до AppManager. <ExternalLink to={'https://help.apple.com/developer-account/#/dev4c557019f'}>Дополнительная информация</ExternalLink></p>
			<p><img className={'figure-img img-fluid'} src="/images/apple-instructions/Screenshot 2021-04-08 at 16.30.28.png" alt=""/></p>
			<p><b>Для тестирования iOS приложения (до релиза) укажите СПИСОК E-MAIL АДРЕСОВ ТЕСТЕРОВ (владельцев iPhone).</b></p>
			<p>На указанные email адреса должны быть зарегистрированы iPhone.</p>
    </div>
	)
}