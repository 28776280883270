import { AppleInstructionPage } from 'pages/AppleInstructionPage/AppleInstructionPage';
import { GoogleInstructionPage } from 'pages/GoogleInstructionPage/GoogleInstructionPage';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loader from 'components/loader';
const App = lazy(() => import('app'));

export default function Routing() {
  return (
    <Router>
      <Suspense fallback={<Loader/>}>
      <Switch>
        <Route path="/apple-instructions" component={AppleInstructionPage}/>
        <Route path="/google-instructions" component={GoogleInstructionPage}/>
        <Route path={["/(.*)/:tokenId", "/:tokenId"]} render={() => <App/>}/>
        <Route path="/" render={() => <div>Not found</div>}/>
      </Switch>
      </Suspense>
    </Router>
  );
}
