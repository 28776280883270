import { Base64 } from "models";

export const base64ConvertToImage = (src: Base64) => {
  const image = new Image();
  image.src = src as string;
  return new Promise<HTMLImageElement>((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = reject;
  });
};
