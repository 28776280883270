import React, { FC } from "react";

interface IExternalLinkProps {
  to: string;
}

export const ExternalLink: FC<IExternalLinkProps> = ({ to, children }) => {
	return (
    <a target='_blank'
       rel="noopener noreferrer"
       href={to}>
      {children}
    </a>
	)
}