const urlRegExp = /^(https?):\/\/(-\.)?([^\s/?.#-]+\.?)+(\/[^\s]*)?$/i;

const restrictions: { [key in string]: any } = {
    title: {
        type: "string",
        notEmpty: true,
        regExp: /^[a-zA-Zа-яА-ЯёЁ0-9,.!?\-\s]*$/,
    },
    promotion: {
        type: "string",
        notEmpty: true,
        maxLength: 80,
    },
    privacyPolicy: {
        type: "string",
        notEmpty: true,
        maxLength: 255,
        regExp: urlRegExp
    },
    support: {
        type: "string",
        notEmpty: true,
        regExp: urlRegExp
    },
    copyright: {
        type: "string",
        notEmpty: true,
    },
    contactInfo: {
        type: "string",
        notEmpty: true,
    },
    tags: {
        type: "string",
        notEmpty: true,
    },
    short_description: {
        type: "string",
        maxLength: 80,
        notEmpty: true,
    },
    description: {
        type: "string",
        maxLength: 4000,
        notEmpty: true,
    },
    stores_apple_key_id: {
        type: "string",
        notEmpty: true,
    },
    stores_apple_issuer_id: {
        type: "string",
        notEmpty: true,
    },
    stores_apple_api_key: {
        type: "string",
        notEmpty: true,
    },
    stores_google_api_key: {
        type: "string",
        notEmpty: true,
    },
    files_icon: {
        type: "file",
        fileType: "image/png",
        notEmpty: true,
        minWidth: 1024,
        noAlpha: true,
        minHeight: 1024,
    },
    files_logo: {
        type: "file",
        fileType: "image/png",
        notEmpty: true,
        width: 1200,
        minHeight: 258,
        maxHeight: 462,
    },
    files_banner: {
        type: "file",
        fileType: "image/png",
        notEmpty: true,
        width: 1216,
        minHeight: 538,
        maxHeight: 688,
    },
    files_storeIcon: {
        type: "file",
        fileType: "image/png",
        notEmpty: true,
        minWidth: 1024,
        minHeight: 1024,
    },
    files_storeBanner: {
        type: "file",
        fileType: "image/png",
        notEmpty: true,
        minWidth: 1024,
        minHeight: 512,
    },
    files_AndroidBanner: {
        type: "file",
        fileType: "image/png",
        notEmpty: true,
        width: 512,
        height: 512,
        size: "1024kb",
    },
    files_googlePlayBanner: {
        type: "file",
        fileType: "image/png",
        notEmpty: true,
        noAlpha: true,
        width: 1024,
        height: 500,
    },
    files_iosBanner: {
        type: "file",
        fileType: "image/png",
        notEmpty: true,
        noAlpha: true,
        width: 1024,
        height: 1024,
    },
};

export default restrictions;
