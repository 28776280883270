import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Epic, ofType } from 'redux-observable';
import { debounceTime, mapTo } from 'rxjs/operators';

export enum NotificationStatus {
    success="success",
    danger="danger",
    info="info",
    warning="warning",
    dark="dark",
}

export type InitialState = {
    isOpen: boolean,
    status: NotificationStatus
    caption: string | string[],
}

export const initialState:InitialState = {
    caption: '',
    isOpen: false,
    status: NotificationStatus.dark,
}

const notificationStore = createSlice({
    name: "notification",
    initialState,
    reducers: {
        add (state, {payload}: PayloadAction<[string, NotificationStatus]>) {
            state.isOpen = true;
            state.caption = payload[0];
            state.status = payload[1];
        },
        remove (state) {
            state.isOpen = false;
        }
    }
});

/** Автоматическое удаление по прошествию времени */
export const removeEpic: Epic = action$ => action$.pipe(
    ofType(notificationStore.actions.add.type),
    debounceTime(15000),
    mapTo(notificationStore.actions.remove()),
)

export const epics = [removeEpic];
export default notificationStore;
//
