import React from "react";
import ReactDOM from "react-dom";
import Routing from "./router";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import SettingStore, {
  initialState as settingState,
  epics as epicSetting,
} from "./stores/settings";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { ApiEpic } from "./api";
import notificationStore, {
  initialState as notificationState,
  epics as epicNotification,
} from "stores/notification";
import fileStore, {
  initialState as fileState,
  epics as epicFiles,
} from "stores/files";
import "bootstrap/dist/css/bootstrap.min.css";
export const mainReducer = combineReducers({
  setting: SettingStore.reducer,
  notification: notificationStore.reducer,
  files: fileStore.reducer,
});
export type GState = ReturnType<typeof mainReducer>;
export const preloadedState = {
  setting: settingState,
  notification: notificationState,
  files: fileState,
};
export const epicMiddleware = createEpicMiddleware();
export const store = configureStore({
  reducer: mainReducer,
  devTools: process.env.NODE_ENV === "development",
  preloadedState,
  middleware: [epicMiddleware],
});
export const epics = combineEpics(
  ApiEpic,
  ...epicSetting,
  ...epicNotification,
  ...epicFiles
);
epicMiddleware.run(epics);

if (process.env.NODE_ENV !== "test")
  ReactDOM.render(
    <Provider store={store}>
      <Routing />
    </Provider>,
    document.getElementById("app")
  );
