const canvas = document.createElement("canvas");
const ctx = canvas.getContext("2d");
export const isTransparent = (image: HTMLImageElement) => {
  canvas.width = image.width;
  canvas.height = image.height;
  ctx?.clearRect(0, 0, canvas.width, canvas.height);
  ctx?.drawImage(image, 0, 0);
  const data = ctx?.getImageData(0, 0, canvas.width, canvas.height)?.data || [];
  for (var i = 0; i < data.length; i += 4) {
    if (data[i + 3] < 255) {
      return true;
    }
  }
  return false;
};