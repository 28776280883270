import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosObservable from 'axios-observable';
import { Epic, ofType } from 'redux-observable';
import SettingStore from '../stores/settings';
import { map, ignoreElements } from 'rxjs/operators'
import { HexColor, Base64 } from '../models';

let instance: AxiosInstance;
let observableInstance: axiosObservable;

/**
 * Получить инстан запроса
 */
export const getInstance = () => instance;

/**
 * Получить инстан запроса
 */
export const getInstanceOb = () => observableInstance;

/**
 * Пробрасывание базового урла используя новый токен
 * @param action$ 
 */
export const ApiEpic: Epic = (action$) =>
    action$.pipe(
        ofType(SettingStore.actions.initTokenId.type),
        map(({ payload: tokenId }) => {
            const config: AxiosRequestConfig = {
                baseURL: `${process.env.REACT_APP_API_URL}/${tokenId}/`
            }
            instance = axios.create(config);
            observableInstance = axiosObservable.create(config)
        }),
        ignoreElements(),
    )

export enum ESettingsStatus {
    IN_PROGRESS= "in_progress",
    REVIEW= "review",
    APPROVED= "approved",
    CLOSED= "closed",
}

export type Settings = {
    colors: {
        accent: HexColor,
        errorText: HexColor,
        primary: HexColor,
        successText: HexColor,
        text: HexColor,
    },
    short_description: string,
    description: string,
    title: string,
    promotion: string,
    privacyPolicy: string,
    support: string,
    copyright: string,
    contactInfo: string,
    tags: string;
    files?: {
        banner: Base64,
        icon: Base64,
        logo: Base64,
        googlePlayBanner: Base64,
        foreground: Base64 | string,
        background: Base64 | string,
    },
    stores?: {
        apple: {
            key_id: string;
            issuer_id: string;
            api_key: Base64;
        };
        google: {
            api_key: Base64;
        }
    }
}
export type ResponseLoadSettings = {
    settings: Settings,
    status?: ESettingsStatus,
}
export const loadSetting = () => getInstanceOb().get<ResponseLoadSettings>('/');
export const saveSetting = (data: ResponseLoadSettings) => getInstanceOb().put<ResponseLoadSettings>('/', data);